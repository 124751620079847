.youtubeCard{
    width: 300px;
    padding: 1vmax;
    margin: 2vmax;
    transition: all 0.5s;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
}
.youtubeCard > a {
    width: inherit;
    text-decoration: none;
    color: rgba(0, 0, 0, 0.712);
}
.youtubeCard  img {
    width: inherit;
}
.youtubeCard:hover{
    transform: scale(1.1);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.329);
}

@media screen and (max-width:600px) {
    .youtubeCard{
        width: 200px;
    }
}