.about {
    background-color: hsl(219, 48%, 8%);
    min-height: 100vh;
    display: flex;
}

.aboutContainer {
    min-height: 70vh;
    width: 60%;
    background-color: rgb(255, 255, 255);
    position: absolute;
    right: 0;
    display: flex;
    justify-content: right;
    padding: 2vmax;
    box-sizing: border-box;

}

.aboutContainer>p {
    width: 30%;
    text-align: right;
    letter-spacing: 5px;
    word-wrap: 5px;
    line-height: 60px;
    font-size: 1.5vmax;
    color: rgba(15, 15, 15, 0.822);
}

.aboutContainer2 {
    width: 60%;
    background-color: white;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.281);
    min-height: 80vh;
    position: absolute;
    bottom: 0;
    display: flex;
    box-sizing: border-box;
    padding: 2vmax;
    animation: aboutAnimation 1s ease-in forwards; 
    transform: translateX(-100vw) translateY(-10%) rotateZ(-360deg);
}
.aboutContainer2 > div {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 1vmax;
    box-sizing: border-box;
    color: rgba(17, 17, 17, 0.712);
}
.aboutContainer2 > div:first-child{
    align-items: center;
}
.aboutAvatar{
    width: 300px;
    height: 300px;
    border-radius: 100%;
    transition: all 1s;
}
.aboutAvatar:hover{
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.452);
    transform: scale(1.05);
}

@keyframes aboutAnimation {
    to{
        transform: translateX(20%) translateY(-10%);
    }
}

@keyframes aboutAnimationPhone {
    to{
        transform: translateX(0%) translateY(0%);
    }
}



/* media secreen  */
@media screen and (max-width: 600px) {
    .about {
      align-items: flex-end;
      padding-top: 100px;
    }
    .aboutContainer {
      display: none;
    }
  
    .aboutContainer2 {
      width: 100%;
      position: relative;
      flex-direction: column;
      animation: aboutAnimationPhone 1s ease-in forwards;
    }
  
    .aboutContainer2 > div:last-child > p {
      text-align: center !important;
    }
  
    .aboutAvatar {
      width: 100px;
      height: 100px;
       transform: translateY(-80%); 
    }
  }
  