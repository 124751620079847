.login{
    width: 100%;
    height: 100vh;
    background-color: hsl(219, 48%, 8%);
    display: flex;
    justify-content: center;
    align-items: center;
}
.loginContainer{
    width: 50%;
    border-radius: 5px;
    background-color: white;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.336);
}
.loginForm{
    height: inherit;
    border-radius: 5px;
    height: 70vh;
    padding: 2vmax;
}
.loginForm >h4 {
    margin: 2vmax;
    letter-spacing: 15px;
    display: flex;
    justify-content: center;
    transform: translateX(-100vw);
    animation: contactFormInputs 1s ease-in-out forwards;

}
.loginForm >h4 > p{
    transition: all 0.5s;
    cursor: default;
}

.loginForm > h4 > p:hover{
    transform: translateY(-10px);
}
.loginForm > div {
    display: flex;
    justify-content: center;
    flex-direction: column;
    box-sizing: border-box;
    padding: 2vmax 8vmax;
}
.loginForm > div > input{
    padding: 1vmax 2vmax;
    margin: 1vmax;
    border: 1px solid rgba(0, 0, 0, 0.192);
    transform: translateX(-100vw);
    border-radius: 30px;
    font: 100 1rem "Roboto";
    outline: none;
    animation: contactFormInputs 1s ease-in-out forwards 0.5s;
}

.loginForm >div > button {
    padding: 1vmax 2vmax;
    margin: 1vmax;
    transform: translateY(-100vh);
    border-radius: 30px;
    background-color: hsl(250, 100%, 75%);
    color: white;
    animation: contactFormBtn 1s ease-in-out forwards 1s;
    transition: all 0.5s;
}

.loginForm >div > button:hover{
    background-color: hsl(219, 48%, 8%);
}

@media screen and (max-width : 600px) {
    .loginContainer{
        width: 100%;
        border-radius: 0;
    }
    .loginForm{
        border-radius: 0;
    }
    .loginForm > h4{
        margin: 1vmax;
    letter-spacing: 10px;
    }
    .loginForm > div {
        padding: 8vw 6vw;
    }
    .loginForm > div > input{
        padding: 3vw 6vw;
        margin: 2vw;
    }
    .loginForm > div > button{
        padding: 3vw 6vw;
        margin: 2vw;
    }
}